import { forwardRef } from 'react';

type InputFieldProps = {
  label: string;
  type: 'text' | 'email' | 'password' | 'number';
  name: string;
  id: string;
  error?: string;
  errors?: string[];
  defaultValue?: string; // defaultValueを追加
};

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  ({ label, type, name, id, error, errors, defaultValue }, ref) => (
    <div className="flex flex-col gap-3">
      <label className="font-semibold text-xs" htmlFor={id}>
        {label}
      </label>
      <div
        className={`overflow-hidden rounded-md outline-0 border ${
          (errors && errors.length > 0) || error
            ? 'border-critical'
            : 'border-indigo-100'
        } focus-within:border-indigo-300 focus-within:ring-2 focus-within:ring-indigo-200 transition-all`}
      >
        <input
          ref={ref}
          className="w-full h-[32px] px-2.5 text-gray-900 outline-0 bg-transparent text-xs"
          type={type}
          name={name}
          id={id}
          defaultValue={defaultValue} // defaultValueを設定
        />
      </div>
      {errors &&
        errors.map((err, index) => (
          <div key={index} className="text-critical text-xs" id={id + '-error'}>
            {err}
          </div>
        ))}
    </div>
  ),
);

InputField.displayName = 'InputField';
